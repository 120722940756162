import React from 'react';

function PreLoader(props) {

    return (
        <div className='preloader'>
            Loading...
        </div>
    );
}

export default PreLoader;
